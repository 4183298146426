@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@100;200;300;400;500;600;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Work Sans", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  max-width: 1400px;
  margin: 0 auto;
}

html {
  background-color: #232121;
}

/* ------------------------------------------------------ */
/* -- Navigation -- */

/* header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 0 25px;
} */

.about,
.projects,
.experience,
.social,
.footer {
  padding: 50px 0px 0px;
}

.about,
.projects,
.experience,
.social,
.footer {
  padding-right: 40px;
  padding-left: 40px;
}

p, h1, h2 {
  color: white;

  font-size: xx-large;
}

.buttonhead p {
  color:white;
}

/* ------------------------------------------------------ */
/* -- Header -- */

.header {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  text-align: center;

  align-items: flex-start;

  background-image: url('./assets/vecteezy_fragment-of-a-modern-office-building-abstract-geometric_46187674.png');
  background-size: contain;
  background-repeat: no-repeat;

  padding-bottom: 5em;
}

.header__content h1 {
  font-size: 100px;
  text-align: center;

  margin-left: 1.2em;

  font-family: Verdana, Geneva, Tahoma, sans-serif;
}


.header__content {
  width: 700px;
  margin: 0 auto;

  align-items: center;
  text-align: center;
}

.header__content h1 {
  font-size: 75px;
  max-width: 500px;
  max-height: 500px;

  margin-right: -20em;
}

.headerimg {
  height: 40%;
  width: 40%;

  border: transparent;
  border-radius: 10px;
}

.headerimgcontainer {
  width: 100vw; /* Sets the width to 100% of the viewport width */
    /* Optional: Remove margin and padding to ensure full-width effect */
  left: 0; /* Align to the left edge */  
  color: white;

  background-color: #FFFFFF;

  margin-bottom: 5em;

  padding-top: 1em;
  padding-bottom: 1em;
}

.header__content p {
  display: flex;
  flex-direction: column;
  font-size: 32px;
  font-weight: bold;
  align-items: center;
}

.header__content button {
  margin: 25px auto 0;
}

.header__content .buttonhead {

  width: 500px;
  height: 300px;

  font-variant: small-caps;

  border-radius: 10%;
  border: solid black 5px;

  align-items: center;

  padding: 6%;
}

.header__content .buttonhead:hover {
  transition: all 500ms ease;
  width: 500px;
  height: 300px;

  font-variant: small-caps;

  border-radius: 10%;

  background-color:#01169d;
  border: solid #01169d 5px;
}

.buttonhead p {
  line-height: 1.5;
}

.buttonhead .phone {
  color:#000000;
}

.reviews-container {
  width: 80%;
  margin: 0 auto;
  padding: 20px;
  text-align: center;

  margin-top: 15em;

  background-color: #fff;

  border: transparent;
  border-radius: 50px;

}

.reviews-container h2 {
  font-size: 2rem;
  margin-bottom: 20px;

  color: #000000;
}

.review-slideshow {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.review-card {
  display: flex;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  background-color: #fff;
  padding: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 600px;
  text-align: left;
}

.review-profile-img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 15px;
}

.review-content {
  flex: 1;
}

.review-content h3 {
  margin: 0;
  font-size: 1.2rem;
}

.reviewsheader .review-rating {
  margin: 0;
}

.reviewsheader h2 {
  margin: 0;
}

.reviewsheader img {
  height: 10%;
  width: 10%;

  margin-bottom: 1em;
}

.review-rating {
  margin: 5px 0;
  color: #f39c12;
  font-size: 1.1rem;
}

.review-content p {
  margin: 0;
  font-size: 1rem;
  color: #555;
}

.nav-button {
  background: none;
  border: none;
  font-size: 2rem;
  cursor: pointer;
  color: #06144b;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

.nav-button:hover {
}

.alert {
	background: #222D41;

	height: 100px;
	width: 300px;

	position: fixed;
	bottom: 1.50em;
	right: 1.50em;
	z-index: 1;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	text-align: center;

	cursor: pointer;

	transition: all 500ms ease;
}


.wrapper .alert--messages {
	background: #222D41;

	height: 600px;
	width: 300px;

	position: fixed;
	bottom: 1.50em;
	right: 1.50em;
	z-index: 1;

	display: flex;
	flex-direction: column-reverse;
	justify-content: center;
	align-items: center;

	text-align: center;

	transition: all 500ms ease;
	overflow: auto;
	scrollbar-width: thin;
}

.alert--messages::-webkit-scrollbar {
	background: var(--clr-blue);
	width: 4px;
}

.alert--messages::-webkit-scrollbar-thumb {
	background: var(--clr-white);
	border-radius: 15px;
}

.wrapper {
	height: 600px;
	width: 300px;
	background: #131413;
	border: 2px solid black;

	position: fixed;
	bottom: 1.50em;
	right: 1.50em;

	overflow: hidden;
}

.alertcontainer {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center;     /* Center vertically */
}

.alert2 {
	background: #131413;
  padding: 2em;

	height: 600px;
	width: 550px;

  position: fixed;         /* Fixes the position relative to the viewport */
  top: 50%;                /* Center vertically */
  left: 50%;               /* Center horizontally */
  transform: translate(-50%, -50%); /* Adjust position to exact center */
  /* Additional styling for the element */

	text-align: center;

	transition: all 500ms ease;
}

.alert2--form label {
  color: white;

  margin-top: 0.5em;
}

.alert2 form {
  color: white;

  display: flex;
  flex-direction: column;
}

.alertform--small input {
  display: flex;
  flex-direction: column;
  
  padding-top: -3em;
}

.alertform--small label {
  font-size: small;
}

.alert2--input {
	background: #DEDEDE;
	color: #000000;

	border: none;
	border-radius: 10px;

	width: 40%;
	margin: 0.5em auto;
	padding: 0.7em;

	font-family: "DM Sans";
	font-weight: 400;

	outline: none;
	position: relative;
}

.alert2--notes {
  padding: 1em;
}

.alert2--deposit {
	background: #131413;

	height: 1000px;
	width: 550px;

	position: fixed;
	bottom: 13em;
	right: 60em;
	z-index: 1;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	text-align: center;

	transition: all 500ms ease;
}

.alert2--balance {
	background: #0E0E0E;
	color: var(--clr-white);

	display: flex;
	flex-direction: row;

	border: 5px solid var(--clr-blue);
	border-radius: 10px;

	width: 100%;
	margin: 0.75em;
	padding: 1.5em;

	font-weight: 700;

	cursor: pointer;
	position: relative;

	transition: all 250ms ease;
}

.alert2--balance:hover {
	color: var(--clr-white);
	border: 5px solid var(--clr-white);
}

.alert2--balance h1 {
  margin-left: 3em;
  margin-top: .3em;
}

.alert2--address {
	background-color: var(--clr-secondary);
	height: 55px;
	width: 500px;
	border-radius: 20px;

	margin: 1em;
}

.alert2--address h1 {
	margin-right: 12em;
	margin-bottom: 0.3em;
	font-size: large;
}

.alert2--address p {
	margin-right: 5em;
	color: var(--clr-neutral);
}

.alert2--content {
	margin: 2em;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	text-align: center;
	height: 300px;
	width: 350px;
}

.alert2--content form {
	margin: 1em;
	display: flex;
	flex-direction: row;

	bottom: 35em;
	right: 74em;
}

.alert2 button {
  color: white;
  background-color: #001075;
  margin-top: 1em;
}

.alert2--content form button:hover {
	width: 80px;
	height: 30px;
	color: var(--clr-white);
	border: 2px solid var(--clr-white);
}

.alert2--content span::after {
	content: '';
	background-size: contain;
	background-repeat: no-repeat;

	position: absolute;
	top: 50%;
	right: 6px;
	transform: translate(50%, -50%);

	width: 10px;
	height: 12px;

	transition: all 250ms ease;
}

.alert2--content button:hover span::after {
	background-size: 7px;
	right: 12px;

	transition: all 200ms ease;
}

.alert2--content form img {
  margin-right: 5px;
  margin-left: -10px;
  width: 15px;
  height: 15px;	
}

.alert2--content p img {
	width: 25px;
  height: 25px;

  transform: translateX(-10px) translateY(7px);
}

.close {
	height: 40px;
	width: 40px;
  position: absolute;
  top: 20px;               /* Distance from the top of the modal */
  left: 20px;    
	transition: 600ms ease;

	cursor: pointer;
}

.alert2--warning {
	background: var(--clr-red);
	border-radius: 30px;
	width: 250px;

	margin: 10px;

	font-size: medium;

}

.alert2--warning--remove {
	opacity: 0;
	pointer-events: none;
}


.close:hover {
	transform: rotate(180deg);
	transition: 400ms ease;
}

.alert a {
	color: var(--clr-blue);
	text-decoration: none;
	margin: 0.25em;
}

.alert a:hover {
	color: var(--clr-white);
}

.alert--remove {
	opacity: 0;
	pointer-events: none;
}

.messagetable {
	margin-bottom: 1em;
}

.messageBody {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.messageLabel {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	font-size: xx-large;
	z-index: 10;
	background: #222D41;
	border-bottom: 10px solid rgba(0, 0, 0, .5);
	border-top: 10px solid rgba(0, 0, 0, .5);
	position: fixed;
	top: 728px;
	right: 257px;
}
.messagetable2 {
	margin-bottom: 1em;
}

.messageBody2 {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
}

.messageLabel2 {
	display: flex;
	flex-direction: column-reverse;
	align-items: flex-end;
	font-size: xx-large;
	z-index: 10;
	background: #222D41;
	border-bottom: 10px solid rgba(0, 0, 0, .5);
	border-top: 10px solid rgba(0, 0, 0, .5);
	position: fixed;
	top: 728px;
	right: 30px;

	min-width: 68px;
	align-items: center;
}

.messageform {
	position: fixed;
	max-width: 150px;
	max-height: 70px;

	bottom: 100px;
}

.messageTables {
	display: flex;
	flex-direction: row;
	margin-bottom: -20em;
}

.messageButton {
	background: #0E0E0E;
	color: var(--clr-blue);

	border: 5px solid var(--clr-blue);
	border-radius: 3px;

	width: 50%;
	height: 50%;

	padding: 1.15em;

	font-weight: bolder;
	font-family: "DM Sans";

	cursor: pointer;
	position: relative;

	transition: all 250ms ease
}


.prev {
  left: 10px;
}

.next {
  right: 10px;
}

/* ------------------------------------------------------ */
/* -- About -- */

.about h2 {
  font-size: 42px;
  margin: 10px auto;
}

.about p {
  font-size: 18px;
  line-height: 130%;
  max-width: 75ch;
  width: 50%;
}

/* ------------------------------------------------------ */
/* -- Projects -- */

.projects h2 {
  font-size: 42px;
  margin: 25px auto;
}

.projects__main {
  display: flex;
  flex-direction: column;

  align-items: center;
  text-align: center;

  max-width: 100%;
}

.projects p {
  font-size: 20px;
  max-width: 50ch;
}

.projects__cards {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 30px;
}

.projects__card {
  max-width: 800px;

  padding: 35px;

  border: 2px solid #000000;
  border-radius: 100px;
  }

.projects__card__main {
  width: 70%;
  max-height:700px;

  padding: 35px;

  border: 4px solid #000000;
  border-radius: 100px;
  margin-bottom: 5em;
}

.projects__card h3 {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 10px;
  color: white;
}

.projects__card__main h3 {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 10px;
  color: white;

  text-align: center;
}

.projects__card p {
  font-size: 16px;
  margin: 20px 0;

  height: 20%;
}

.projects__card__main p {
  font-size: 20px;
  margin: 20px 0;

  height: 20%;

  margin-left: 10em;
}

.projects__card img {
  width: 100%;
  height: 100%;
  margin: 20px 0 10px;

  border-radius: 30px;

  min-height: 100px;
  max-height: 300px;

  min-width: 200px;
  max-width: 400px;

}

.projects__card__main img {
  width: 70%;
  max-height: 350px;
  margin: 20px 0 10px;
  
  margin-left: 5em;
  margin-top: 3em;
  margin-bottom: 1em;

  border: 3px solid black;

  border-radius: 40px;
}

.projects__card .button {
  margin: 10px 5px 0;
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}


/* ------------------------------------------------------ */
/* -- Education/Experience -- */

.experience h2 {
  font-size: 42px;
  margin: 25px auto;
}

.experience ul {
  margin: 0 0 10px 50px;
  color: white;
}

.experience li {
  font-size: 18px;
  line-height: 130%;
  margin: 15px auto;
}

.experience p {
  display: flex;
  flex-direction: column;
  word-spacing: normal;
  
  margin-top: 3em;

  align-items: center;

}

.experience .buttonhead {
  width: 50%;
  margin-top: 2em;

  border-width: 5px;

  font-variant: small-caps;
}

.experience .buttonhead:hover {
  transition: all 500ms ease;

  font-variant: small-caps;

  background-color:#01169d;
  border: solid #01169d 5px;
}

.experience .buttonhead p {
  margin-top: 1em;
}

.about img {
  width: 40%;

  border: 5px solid black;
  border-radius: 50px;

  transform: translateX(150%);
  
  margin-bottom: -30em;
}

.experience i {
  color:#001075;
}

.about h1 {
  text-align: center;
}

/* ------------------------------------------------------ */
/* -- Social Media -- */

.social h2 {
  font-size: 42px;
  margin: 25px auto;
}

.social .button {
  margin: 0 15px;
}

/* ------------------------------------------------------ */
/* -- Footer -- */
.footer {
  margin: 0 auto;
  text-align: center;
}

/* ------------------------------------------------------ */
/* -- HR Tags -- */
.line {
  padding: 1px 2px;
  border: 1px solid black;
  border-radius: 20px;
  margin: 20px;

  height: 1;

  max-width: 95%;

}

.projects__card__main hr {
  border: 3px solid black;
  margin-left: 15em;

  width: 55%;
}

.fade-in {
  opacity: 0;  /* Initially hidden */
  transition: opacity 2s ease-in-out; /* Smooth transition */
}

.fade-in-visible {
  opacity: 1;  /* Fully visible */
}

/* ------------------------------------------------------ */
/* -- HTML COMPONENTS: BUTTON -- */
.button {
  display: inline-block;

  background: #010c54;

  padding: 20px 50px;

  border: none;
  border-radius: 20px;

  font-family: "Work Sans";
  font-size: 16px;
  font-weight: 700;
  text-decoration: none;
  color: black;
  cursor: pointer;

  transition: all 250ms ease;
}

.button__WIP {
  display: inline-block;

  background: #302f2d;

  padding: 20px 50px;

  border: none;
  border-radius: 20px;

  font-family: "Work Sans";
  font-size: 16px;
  font-weight: 700;
  text-decoration: none;
  color: black;
  cursor: pointer;

  transition: all 250ms ease;
}

.buttonhead {
  display: inline-block;

  background: #001075;

  border: 20px solid #04000a;
  border-radius: 35px;

  font-family: "Work Sans";
  font-size: 16px;
  font-weight: 700;
  text-decoration: none;
  color: black;
  cursor: pointer;

  transition: all 250ms ease;
}

.button:hover {
  background: #7729ff;
  color: #FFFFFF;
}


@media only screen and (max-width: 1200px) {
  .projects {
    width: 1500px;
  }

  .projects__cards {
    display: flex;
    flex-direction: row;
    width: 1400px;
    margin-left: 2em;
  }

  .projects h2 {
    text-align: center;
  }

  .projects__card {
    max-width: 400px;
    margin: 1em;
  }
  
  .projects__card p {
    font-size: 30px;
  }

  .line {
    transform: translateX(150px);
    margin-top: 5em;
  }

  .App {
    width: 1600px;
  }

  .experience li {
    font-size: 30px;
    margin-bottom: 1em;
  }

  .experience i {
    font-size: 35px;
  }

  .about p {
    font-size: xx-large;
  }

  .buttonhead {
    font-size: xx-large;
  }

  .button__WIP {
    transform: translateX(-5px);
  }

  .header__content h1 {
    font-size: 70px;
    margin-bottom: 0.3em;
  }

  .header__content p {
    font-size: 40px;
  }

  .projects__card h3 {
    font-size: 60px;
  }

}